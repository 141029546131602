import { useStaticQuery, graphql } from 'gatsby'
import { News } from './news'

export const useVodaNews = (): News[] => {
  const data = useStaticQuery(graphql`
    {
      allNews(
      sort: { fields: publishedAt, order: DESC }
      filter: { influencedProjectIds: { eq: 3 } }
      ) {
        nodes {
          newsId
          publishedAt
          likesCount
          viewsCount
          title {
            content
            language
          }
          shortDescription {
            content
            language
          }
          mainImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77, placeholder: BLURRED)
            }
          }
          attachmentImages {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `).allNews.nodes

  return data.map(({ newsId, mainImage, attachmentImages, ...rest }: any) => ({
    ...rest,
    id: newsId,
    mainImage: mainImage?.childImageSharp?.gatsbyImageData,
    attachmentImages: attachmentImages.map(
      (attachmentImage: any) => attachmentImage.image?.childImageSharp?.gatsbyImageData,
    ),
  }))
}
